import { Currencies, Signs } from "../enums/StringEnums";

export const capitalizeFirstLetter = (s) => {
    if(!s) return s;
    const firstLetter = s.charAt(0)
    const firstLetterCap = firstLetter.toUpperCase()
    const remainingLetters = s.slice(1)
    const capitalizedWord = firstLetterCap + remainingLetters

    return capitalizedWord;
}

export const toMoney = (str, currency = Currencies.DOLLAR) => {
    if(!str.length) return str;
    var result = "";

    if(str.charAt(0) === '-') {
        result = `-${currency}${str.slice(1)}`
    }
    else {
        result = currency + str;
    }

    return result;
}

export const toPercentage = (str) => {   
    if(!str.toString().length) return str;

    return `${str}${Signs.PERCENTAGE}`;
}
