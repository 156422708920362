import Enum from '@/utils/helpers/EnumHelper.js';

const Securities = Enum({
    TVLOGIN: 'TVLOGIN',  // access to entire TM portal
    TMLOGIN: 'TMLOGIN',  // access to index page of TM portal
    TVADMIN: 'TVADMIN',
    TMSMYRPT: 'TMSMYRPT', // access to Tire Optix Reporting
    TMSMDRPT: 'TMSMDRPT', // access to Dealer Tire Optix Reporting
});

const Groups = Enum({
    GYR: "GYR",
    CUS: "CUS",
    DLR: "DLR",
    WIN: "WIN"
})

const ReportSecurities = Enum({
    SCRAP_ANALYSIS: "TVSCRPAN",
    FLEET_CONDITION: "TVFLTCNR",
    PRODUCT_ANALYSIS: "TVPRDANL",
    PRODUCT_WEAR_RATE: "TVWEARRT",
    UNIT_HISTORY: "TMUNTHST",
    TORQUE_SUMMARY: "TMTQSRPT"
});

const DashboardSecurities = Enum({
    FLEET_DASHBOARD: "TVFLTDBS",
});

const UnitHistorySecurities = Enum({
    CUS_LAST_5_EVENTS: 'SPSRHIST',
    DLR_LAST_5_EVENTS: 'SPPRFRPT',
});

export {
    Securities,
    ReportSecurities,
    DashboardSecurities,
    UnitHistorySecurities,
    Groups
}