import ApiService from "@/core/services/api.service";

// action types
export const API_DEALER_TIREOPTIX_REPORT_ACTION_COUNTRIES_WITH_STATES_LOAD = "action_dealer_tireoptix_report_countries_with_states_load";
export const API_DEALER_TIREOPTIX_REPORT_ACTION_DEALERS_LOAD = "action_dealer_tireoptix_report_dealers_load";
export const API_DEALER_TIREOPTIX_REPORT_ACTION_DEALER_LOCATIONS_LOAD = "action_dealer_tireoptix_report_dealer_locations_load";
export const API_DEALER_TIREOPTIX_REPORT_ACTION_SURVEY_BY_USERS_LOAD = "action_dealer_tireoptix_report_survey_by_users_load";
export const API_DEALER_TIREOPTIX_REPORT_ACTION_LOAD = "action_dealer_tireoptix_report_load";
export const API_DEALER_TIREOPTIX_REPORT_ACTION_DOWNLOAD_PDF = "action_dealer_tireoptix_report_download_pdf";
export const API_DEALER_TIREOPTIX_REPORT_ACTION_DOWNLOAD_EXCEL = "action_dealer_tireoptix_report_download_excel";
export const API_DEALER_TIREOPTIX_REPORT_ACTION_DOWNLOAD_ALL_DETAILS = "action_dealer_tireoptix_report_download_all_details";

// muts
export const API_DEALER_TIREOPTIX_REPORT_MUTS_SET_COUNTRIES_WITH_STATES = "muts_dealer_tireoptix_report_set_countries_with_states";
export const API_DEALER_TIREOPTIX_REPORT_MUTS_SET_DEALERS = "muts_dealer_tireoptix_report_set_dealers";
export const API_DEALER_TIREOPTIX_REPORT_MUTS_SET_DEALER_LOCATIONS = "muts_dealer_tireoptix_report_set_dealer_locations";
export const API_DEALER_TIREOPTIX_REPORT_MUTS_SET_SURVEY_BY_USERS = "muts_dealer_tireoptix_report_set_survey_by_users";
export const API_DEALER_TIREOPTIX_REPORT_MUTS_SET_REPORT_PARAMS = "muts_dealer_tireoptix_report_set_report_params";


const state = {
    countries_with_states: [
        {
            country: {
                text: null,
                value: null
            },
            states: [
                {
                    text: null,
                    value: null
                }
            ]
        }
    ],
    dealers: [],
    dealer_locations: [],
    survey_by_users: [
        {
            text: null,
            value: null
        }
    ],
    report_params: {
        records_total: null,
        surveys: null,
        tires: null,
        units: null,
        units_with_exceptions: null,
        total_of_trucks_surveyed: null,
        total_of_trailers_surveyed: null,
        exceptions: null,
        data: [
            {
                survey_id: null,
                fleet_location: null,
                survey_date: null,
                survey_by: null,
                fleet_name: null,
                dealer_location: null,
                total_tires: null,
                total_units: null,
                exceptions: null,
                trailer_readiness: null,
            }
        ]
    }
}

const getters = {
    getDealerTireOptixReportingCountiesWithStates() {
        return state.countries_with_states;
    },
    getDealerTireOptixReportingDealers() {
        return state.dealers;
    },
    getDealerTireOptixReportingDealerLocations() {
        return state.dealer_locations;
    },
    getDealerTireOptixReportingReportParams() {
        return state.report_params;
    },
    getDealerTireOptixReportingTableData() {
        return state.report_params.data;
    },
    getDealerTireOptoxReportingTotalRecords() {
        return state.report_params.records_total;
    },
    getDealerTireOptixReportingSurveyByUsers() {
        return state.survey_by_users;
    },
};

const actions = {
    [API_DEALER_TIREOPTIX_REPORT_ACTION_COUNTRIES_WITH_STATES_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.get("survey/get_countries_with_states", context)
                .then(({ data }) => {
                    context.commit(API_DEALER_TIREOPTIX_REPORT_MUTS_SET_COUNTRIES_WITH_STATES, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_DEALER_TIREOPTIX_REPORT_ACTION_DEALERS_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("survey/get_dealers", params, context)
                .then(({ data }) => {
                    context.commit(API_DEALER_TIREOPTIX_REPORT_MUTS_SET_DEALERS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_DEALER_TIREOPTIX_REPORT_ACTION_DEALER_LOCATIONS_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("survey/get_dealers_locations", params, context)
                .then(({ data }) => {
                    context.commit(API_DEALER_TIREOPTIX_REPORT_MUTS_SET_DEALER_LOCATIONS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_DEALER_TIREOPTIX_REPORT_ACTION_SURVEY_BY_USERS_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("survey/get_survey_by_users", params, context)
                .then(({ data }) => {
                    context.commit(API_DEALER_TIREOPTIX_REPORT_MUTS_SET_SURVEY_BY_USERS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_DEALER_TIREOPTIX_REPORT_ACTION_LOAD](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("survey/report-dealer", params, context)
                .then(({ data }) => {
                    context.commit(API_DEALER_TIREOPTIX_REPORT_MUTS_SET_REPORT_PARAMS, {data});
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_DEALER_TIREOPTIX_REPORT_ACTION_DOWNLOAD_PDF](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.postBlob("survey/download_pdf_file_survey_dealer_reports", params, context)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_DEALER_TIREOPTIX_REPORT_ACTION_DOWNLOAD_EXCEL](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.postBlob("survey/download_excel_file_survey_dealer_reports", params, context)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [API_DEALER_TIREOPTIX_REPORT_ACTION_DOWNLOAD_ALL_DETAILS](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.postBlob("survey/detailsdealerreport", params, context)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    }
};

const mutations = {
    [API_DEALER_TIREOPTIX_REPORT_MUTS_SET_COUNTRIES_WITH_STATES](state, {data}) {
        state.countries_with_states = data.countries_with_states;
    },
    [API_DEALER_TIREOPTIX_REPORT_MUTS_SET_DEALERS](state, {data}) {
        state.dealers = data.dealers;
    },
    [API_DEALER_TIREOPTIX_REPORT_MUTS_SET_DEALER_LOCATIONS](state, {data}) {
        state.dealer_locations = data.dealers_locations;
    },
    [API_DEALER_TIREOPTIX_REPORT_MUTS_SET_SURVEY_BY_USERS](state, {data}) {
        state.survey_by_users = data.survey_by_users;
    },
    [API_DEALER_TIREOPTIX_REPORT_MUTS_SET_REPORT_PARAMS](state, {data}) {
        state.report_params.records_total = data.records_total;
        state.report_params.surveys = data.surveys;
        state.report_params.tires = data.tires;
        state.report_params.units = data.units;
        state.report_params.units_with_exceptions = data.units_with_exceptions;
        state.report_params.total_of_trucks_surveyed = data.total_of_trucks_surveyed;
        state.report_params.total_of_trailers_surveyed = data.total_of_trailers_surveyed;
        state.report_params.exceptions = data.exceptions;
        state.report_params.data = data.data;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};