import { toCommas } from "../helpers/NumberHelper";
import { toMoney, toPercentage } from "../helpers/StringHelper";
import DateHelper from '../helpers/DateHelper';
export const ERRORS = {
    CANT_FIND_TAB: `Can't find selected tab. Selected tab is undefined`,
    REPORT_PARAMS_CANT_BE_NULL: `Restored report parameters can't be null`,
    REPORT_NOT_FOUND: `Report not found`,
}
export const SELECT_LOCATION_STEP = {
    TITLE: "Select a Fleet",
    NUMBER: 1,
    KEYS: {
        US_NA: "us_national_account",
        CAN_CO: "canadian_common_owner",
        CAN_N: "canadian_nonsig"
    },
    STATE_KEY: "selectLocationStep"
}
export const SELECT_DATE_STEP = {
    TITLE: "Select a Date Range",
    NUMBER: 2,
    RESTORED_NUMBER: 2.1,
    STATE_KEY: "selectDateStep"
}

export const SELECT_FILTER_STEP= {
    TITLE: "Selected Filters",
    NUMBER: 3,
    INNERTAB_LOCATIONS: {
        TITLE: "Location Code",
        SWITCH_TEXT_RIGHT: "Show $n Selected Locations",
        SWITCH_TEXT_LEFT: "Show Locations",
        CHECKBOX_LABEL: "Select All Available Location Codes"
    },
    INNERTAB_NONSIGS: {
        TITLE: "Nonsig",
        SWITCH_TEXT_RIGHT: "Show $n Selected Nonsigs",
        SWITCH_TEXT_LEFT: "Show Nonsigs",
        CHECKBOX_LABEL: "Select All Available Nonsigs"
    },
}

export const LOCAL_STORAGE_KEY = "fleet_dashboard.v2";
export const FLEET_DASHBOARD = "Fleet Dashboard";
export const FLEET_DASHBOARD_REPORT = "Fleet Dashboard Report";

export const SESSION_STORAGE_KEYS = {
    TAB_ID: "tab_id"
}

export const REPORT_TYPE = {
    TOP_5_SERVICING_DEALERS: 0,
    FLEET_HQ_CALLS_BY_MONTH: 1,
    FLEET_HQ_CALLS_BY_STATE: 2,
    FLEET_HQ_CALLS_AVG_ROLL_TIME: 3,
    EMERGENCY_SERVICE: 4,
    RETREAD_SALES_TO_NEW_TIRES_SALES: 5,
    SERVICE_EMERGENCY_VS_SCHEDULED: 6,
    TOP_PRODUCTS: 7,
    REPAIR_AND_RETREAD_COST: 8,
    TOP_5_RETREADERS: 9,
    REJECTED_TIRE_FAILURES_BY_REASON: 10,
    RETREAD_TURNAROUND_TIME: 11,
    REPAIR_SUMMARY: 12,
    STATISTICS_CASING_DURABILITY: 13,
    TOP_10_CASING_BRANDS_CASING_DURABILITY: 14,
    TOP_10_BRANDS_AVERAGE_AGE_CASING_DURABILITY: 15,
    AVERAGE_PREVIOUS_CAPS_CASING_DURABILITY: 16,
    REJECT_TIRE_RATE_CASING_DURABILITY: 17,
    CASINGS_SUBMITTED_CASING_DURABILITY: 18,    
}

export const TAB_TYPES = {
    PURCHASES: 0,
    SERVICE: 1,
    RETREADING: 2,
    CASING_DURABILITY: 3,
    COMBINED: 5
}

export const REPORT_ORDER = {
    [TAB_TYPES.PURCHASES]: [
        REPORT_TYPE.RETREAD_SALES_TO_NEW_TIRES_SALES,
        REPORT_TYPE.EMERGENCY_SERVICE,
        REPORT_TYPE.SERVICE_EMERGENCY_VS_SCHEDULED,
        REPORT_TYPE.REPAIR_AND_RETREAD_COST,
        REPORT_TYPE.TOP_PRODUCTS,
    ],
    [TAB_TYPES.SERVICE]: [
        REPORT_TYPE.TOP_5_SERVICING_DEALERS,
        REPORT_TYPE.FLEET_HQ_CALLS_BY_MONTH,
        REPORT_TYPE.FLEET_HQ_CALLS_BY_STATE,
        REPORT_TYPE.FLEET_HQ_CALLS_AVG_ROLL_TIME,
    ],
    [TAB_TYPES.RETREADING]: [
        REPORT_TYPE.TOP_5_RETREADERS,
        REPORT_TYPE.REJECTED_TIRE_FAILURES_BY_REASON,
        REPORT_TYPE.RETREAD_TURNAROUND_TIME,
        REPORT_TYPE.REPAIR_SUMMARY,
    ],
    [TAB_TYPES.CASING_DURABILITY]: [
        REPORT_TYPE.STATISTICS_CASING_DURABILITY,
        REPORT_TYPE.TOP_10_CASING_BRANDS_CASING_DURABILITY,
        REPORT_TYPE.TOP_10_BRANDS_AVERAGE_AGE_CASING_DURABILITY,
        REPORT_TYPE.AVERAGE_PREVIOUS_CAPS_CASING_DURABILITY,
        REPORT_TYPE.REJECT_TIRE_RATE_CASING_DURABILITY,
        REPORT_TYPE.CASINGS_SUBMITTED_CASING_DURABILITY,
    ]
}

export const TAB_TYPE_LABELS = {
    PURCHASES: "Purchases",
    SERVICE: "Service",
    RETREADING: "Retreading",
    CASING_DURABILITY: "CasingDurability",
    COMBINED: "Combined"
}

export const REPORT_TYPE_TITLE = {
    TOP_5_SERVICING_DEALERS: "Top 5 Servicing Dealers",
    FLEET_HQ_CALLS_BY_MONTH: "Fleet HQ Calls by Month",
    FLEET_HQ_CALLS_BY_STATE: "Fleet HQ Calls by State/Providence",
    FLEET_HQ_CALLS_AVG_ROLL_TIME: "Fleet HQ Calls Avg Roll Time (Emergency Only)",
    EMERGENCY_SERVICE: "Emergency Service Spend %",
    RETREAD_SALES_TO_NEW_TIRES_SALES: "Retread Sales to New Tire Sales",
    SERVICE_EMERGENCY_VS_SCHEDULED: "Service (Emergency vs. Scheduled*)",
    REPAIR_AND_RETREAD_COST: "Repairs and Retread Cost",
    TOP_PRODUCTS: 'Top 10 Products Purchased',
    TOP_5_RETREADERS: 'Top 5 Retreaders (Finished Retreads)',
    REJECTED_TIRE_FAILURES_BY_REASON: 'Top 10 Rejected Tire Failures By Reason',
    RETREAD_TURNAROUND_TIME: 'Retread Turnaround Time',
    REPAIR_SUMMARY: 'Repair Summary',

    STATISTICS_CASING_DURABILITY:'Statistics',
    TOP_10_CASING_BRANDS_CASING_DURABILITY:'Casing Brand Count',
    TOP_10_BRANDS_AVERAGE_AGE_CASING_DURABILITY:'Average Brand Age',
    AVERAGE_PREVIOUS_CAPS_CASING_DURABILITY:'Average Previous Caps',
    REJECT_TIRE_RATE_CASING_DURABILITY:'Reject Tire Rate',
    CASINGS_SUBMITTED_CASING_DURABILITY:'Casings Submitted',
    
    UNKNOWN: "Unknown",
}
export const REPORT_TYPE_SUBTITLE = {
    SERVICE_EMERGENCY_VS_SCHEDULED: "*Scheduled does not include stock deliveries",
}

export const REPORT_TYPE_MAP = new Map(Object.keys(REPORT_TYPE).map((key) => {
    return [REPORT_TYPE[key], REPORT_TYPE_TITLE[key]]
}));
export const REPORT_SUBTITLE_MAP = new Map(Object.keys(REPORT_TYPE).map((key) => {
    return [REPORT_TYPE[key], REPORT_TYPE_SUBTITLE[key]]
}));

export const REPORT_PARTS = {
    BOTH: "Both Graph & Table",
    ONLY_GRAPH: "Only Graph",
    ONLY_TABLE: "Only Table"
}
export const INCLUDE_OPTIONS_MAP = new Map([
    [ REPORT_PARTS.BOTH, 0 ] ,
    [ REPORT_PARTS.ONLY_TABLE, 1 ],
    [ REPORT_PARTS.ONLY_GRAPH, 2 ]
]);
export const LOAD_PARTS_ORDER = {
    FULL: [INCLUDE_OPTIONS_MAP.get(REPORT_PARTS.BOTH)],
    TABLE_LAST: [INCLUDE_OPTIONS_MAP.get(REPORT_PARTS.ONLY_GRAPH), INCLUDE_OPTIONS_MAP.get(REPORT_PARTS.ONLY_TABLE)],
    GRAPH_LAST: [INCLUDE_OPTIONS_MAP.get(REPORT_PARTS.ONLY_TABLE), INCLUDE_OPTIONS_MAP.get(REPORT_PARTS.ONLY_GRAPH)],
}

export const REPORT_STATUS_CODES = {
    NEVER_LOADED: 204,
    NOT_LOADED: 400,
    LOADED: 200,
    LOADING: 102
}

export const DROPDOWN_STATUS_CODES = {
    NEVER_LOADED: 0,
    LOADED: 1,
    LOADING: 2
}

export const GRAPH_POINTS_TYPE = {
    CURRENT: "0",
    PREVIOUS: "1",
    NAT_AVG: "2",
}
export const GRAPH_SHAPES = {
    NONE: null,
    DOT: "dot",
    DIAGONAL_RIGHT_LEFT: "diagonal-right-left"
}
export const COEFFICIENT_MINIMUM = 90.0;

export const SERVICE_TYPE_KEYS = {
    EMERGENCY: "emergency",
    SCHEDULED: "scheduled",
    STOCK: "stock"
}
export const GRAPH_SHAPE_MAP = new Map([
    [SERVICE_TYPE_KEYS.EMERGENCY, GRAPH_SHAPES.DIAGONAL_RIGHT_LEFT],
    [SERVICE_TYPE_KEYS.SCHEDULED, GRAPH_SHAPES.DOT],
])
export const GRAPH_TYPE_TEXT = {
    [GRAPH_POINTS_TYPE.CURRENT]: "Current",
    [GRAPH_POINTS_TYPE.PREVIOUS]: "Previous",
    [GRAPH_POINTS_TYPE.NAT_AVG]: "Nat'l Avg",
}

export const TOOLTIP_TEXT = {
    ADD_TO_REPORT: `Clicking on "Add to Report" allows you to combine graphs from across all sections into one report. The table, graph, filters and comments will be locked and added to the "Report" Tab.`,
    ADDED: ` Clicking on "Added" will remove this _reportPart from the combined report and will allow you to make edits to the filters and comments.`
}
export const TOOLTIP_TEXT_MAP = new Map([
    [REPORT_PARTS.BOTH, TOOLTIP_TEXT.ADDED.replace('_reportPart', 'graph')],
    [REPORT_PARTS.ONLY_TABLE, TOOLTIP_TEXT.ADDED.replace('_reportPart', 'table')],
    [REPORT_PARTS.ONLY_GRAPH, TOOLTIP_TEXT.ADDED.replace('_reportPart', 'graph')],
])

export const SUMMARY_LABELS = {
    EMERGENCY: "Emergency Calls",
    SCHEDULED: "Scheduled Calls",
    STOCKED: "Stock Calls",
    TOTAL: "Total Calls",
    RETREAD: "Retread",
    USED_TIRES: "Used Tires (Repair Only)",
    REJECTED: "Rejected",
}
export const SUMMARY_LABELS_CASING_DURABILITY = {
    AVG_AGE: "Average Age",
    AVG_TIMES_RECAP: "Average Times Recap",
    REJECTED_TIRE_RATE: "Rejected Tire Rate %",
    TOTAL_COUNT: "Total Count",
}
export const COLORS = {
    TRANSPARENT65: "A6",
    TOTAL: "#004EA8",
    EMERGENCY: "#FF844D",
    EMERGENCY_PREV: "#FFD1C2",
    SCHEDULED: "#8FC73F",
    SCHEDULED_PREV: "#DFEDAD",
    STOCK: "#7B4DB1",
    GRAY: "gray",
    BLACK: "#000",
    BLUE: "#004EA8",
    LIGHT_BLUE: "#68DBFF",
    GREEN: "#8FC73F",
    YELLOW: "#ffdd00",
    PURPLE: "#9553FF",
    RETREAD: "#8FC73F",
    RETREAD_PREV: "#D8FFB0",
    NEW: "#004EA8",
    NEW_PREV: "#88BCF8",
    REPAIR: "#004EA8",
    REPAIR_PREV: "#88BCF8",    
    RET_TURNAROUND: "#004EA8",
    RET_TURNAROUND_PREV: "#88BCF8",
    RET_REPAIR_SUMMARY_NAIL_HOLES: "#004ea8",
    RET_REPAIR_SUMMARY_NAIL_HOLES_PREV: "#004ea8a6",
    RET_REPAIR_SUMMARY_SECTION: "#8fc73f",
    RET_REPAIR_SUMMARY_SECTION_PREV: "#8fc73fa6",
    RET_REPAIR_SUMMARY_SPOT: "#ffdd00",
    RET_REPAIR_SUMMARY_SPOT_PREV: "#ffdd00a6",
    RET_REPAIR_SUMMARY_BEAD: "#7b4db1",
    RET_REPAIR_SUMMARY_BEAD_PREV: "#7b4db1a6",
    BACKGROUND: "#FFF",
    NAT_AVG: "#7b36e5",
}

export const RESTORE_ACTION = {
    RUN: "RUN",
    EDIT: "EDIT"
}
export const SET_TAB_REPORTS_ACTIONS = {
    RUN_ON_CLICK: "AFTER RUN ON CLICK",
    RUN_WITH_CHANGE: "AFTER BASE PARAMS HAVE BEEN CHANGED",
    RESTORE_FROM_LS: "AFTER RESTORE FROM LOCAL STORAGE",
    RESTORE_AS_RUN: RESTORE_ACTION.RUN, 
    RESTORE_AS_EDIT: RESTORE_ACTION.EDIT, 
    UPDATE: "UPDATE SINGLE"
}

export const EMERGENCY_COLORS = {
    [GRAPH_POINTS_TYPE.CURRENT]: COLORS.EMERGENCY,
    [GRAPH_POINTS_TYPE.PREVIOUS]: COLORS.EMERGENCY_PREV,
}
export const SCHEDULED_COLORS = {
    [GRAPH_POINTS_TYPE.CURRENT]: COLORS.SCHEDULED,
    [GRAPH_POINTS_TYPE.PREVIOUS]: COLORS.SCHEDULED_PREV,
}

export const PIE_COLORS = [
    'rgb(0, 78, 168)',
    'rgb(143, 199, 63)',
    'rgb(255, 221, 0)',
    'rgb(123, 77, 177)',
    'rgb(143, 209, 233)',
    'rgb(34, 169, 224)',
    'rgb(255, 132, 77)',
    'rgb(255, 93, 113)',
    'rgb(9, 123, 195)',
    'rgb(0, 197, 164)',
]

export const CASING_DURABILITY_BARS_COLORS = [
    COLORS.BLUE,
    COLORS.GREEN,
    COLORS.YELLOW,
    COLORS.PURPLE,
    COLORS.LIGHT_BLUE,
]

export const SERVICE_TYPE_CALLS = {
    EMERGENCY: "EmergencyCalls",
    SCHEDULED: "ScheduledCalls",
    STOCK: "StockCalls",
    TOTAL: "TotalCalls",
}
export const MONTHS = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "June",
    7: "July",
    8: "Aug",
    9: "Sept",
    10: "Oct",
    11: "Nov",
    12: "Dec",
}
export const ServiceTypes = {
    emergency: true,
    scheduled: true,
    stock: true,
}

export const ServiceTypeOrder = {
    EMERGENCY: 0,
    SCHEDULED: 1,
    STOCK: 2,
    TOTAL: 3,
}

export const UnitLocations = {
    roadside: true,
    shop: true,
    yard: true,
}
export const UnitLocationLabels = {
    roadside: "Roadside",
    yard: "Fleet Yard",
    shop: "Dealer Shop"
}

export const WheelPosition = {
    steer: true,
    trail: true,
    drive: true,
    allpositions:true,
    Unknown: true,
}
export const WheelPositionLabels = {
    steer: "Steer",
    trail: "Trail",
    drive: "Drive",
    allpositions:"All Positions",
    unknown:"Unknown",
}

export const TireStatus = {
    retread: true,
    used: true,
    rejected: true,
}
export const TireStatusLabels = {
    retread: "Retread",
    used: "Used Tires (Repair Only)",
    rejected: "Rejected",
}

export const ByRetreader = {
    ctsc: true,
    other: true,
}
export const ByRetreaderLabels = {
    ctsc: "CTSC",
    other: "All Other Dealers",
}

export const TableFields = {
    [REPORT_TYPE.FLEET_HQ_CALLS_BY_MONTH]: [
        { key: 'Date', label: 'Month', sortable: true, sortKey: 0, thClass: "sorting", thStyle: 'width: 20%;', tdClass: 'text-nowrap', showMonth: true, show: true,},
        { key: 'back', label: '', sortable: false, thClass: "text-center", thStyle: 'width: 2%;', tdClass: 'text-nowrap',  show: true, showWeek: true,},
        { key: 'WeekString', label: 'Week', sortable: true, sortKey: 1, thClass: "sorting", thStyle: 'width: 20%;', tdClass: 'text-nowrap', showWeek: true, show: true },
        
        { key: 'TotalCalls', label: 'Total Calls (Current)', sortable: true, sortKey: 2, thClass: "sorting", showPrev: true, formatter: (v) => toCommas(v)},
        { key: 'TotalCallsPrev', label: 'Total Calls (Previous)', sortable: true, sortKey: 3, thClass: "sorting", showPrev: true, formatter: (v) => toCommas(v)},
        { key: 'TotalCalls', label: 'Total Calls', sortable: true, sortKey: 2, thClass: "sorting", showCurrent: true, formatter: (v) => toCommas(v)},
        
        { key: 'EmergencyCalls', label: 'Emergency Calls (Current)', sortable: true, sortKey: 4, thClass: "sorting", showPrev: true, formatter: (v) => toCommas(v)},
        { key: 'EmergencyCallsPrev', label: 'Emergency Calls (Previous)', sortable: true, sortKey: 5, thClass: "sorting", showPrev: true, formatter: (v) => toCommas(v)},
        { key: 'EmergencyCalls', label: 'Emergency Calls', sortable: true, sortKey: 4, thClass: "sorting", showCurrent: true, formatter: (v) => toCommas(v)},
        
        { key: 'ScheduledCalls', label: 'Scheduled Calls (Current)', sortable: true,  sortKey: 6, thClass: "sorting", showPrev: true, formatter: (v) => toCommas(v)},
        { key: 'ScheduledCallsPrev', label: 'Scheduled Calls (Previous)', sortable: true, sortKey: 7, thClass: "sorting", showPrev: true, formatter: (v) => toCommas(v)},
        { key: 'ScheduledCalls', label: 'Scheduled Calls', sortable: true, sortKey: 6, thClass: "sorting", showCurrent: true, formatter: (v) => toCommas(v)},
        
        { key: 'StockCalls', label: 'Stock Calls (Current)', sortable: true, sortKey: 8, thClass: "sorting", showPrev: true, formatter: (v) => toCommas(v)},
        { key: 'StockCallsPrev', label: 'Stock Calls (Previous)', sortable: true, sortKey: 9, thClass: "sorting", showPrev: true, formatter: (v) => toCommas(v)},
        { key: 'StockCalls', label: 'Stock Calls', sortable: true, sortKey: 8, thClass: "sorting", showCurrent: true, formatter: (v) => toCommas(v)},
        
        { key: 'arrow', label: 'Service History', sortable: false, thStyle: 'width: 2%', tdClass: "text-center", show: true,},
    ],
    [REPORT_TYPE.FLEET_HQ_CALLS_AVG_ROLL_TIME]: [
        { key: 'Date', label: 'Month', sortable: true, sortKey: 0, thClass: "sorting", thStyle: 'width: 20%;', tdClass: 'text-nowrap', show: true,  showMonth: true,},
        { key: 'back', label: '', sortable: false, thClass: "text-center", thStyle: 'width: 2%;', tdClass: 'text-nowrap',  show: true, showWeek: true,},
        { key: 'WeekString', label: 'Week', sortable: true, sortKey: 1, thClass: "sorting", thStyle: 'width: 20%;', tdClass: 'text-nowrap',  show: true, showWeek: true,},
        { key: 'Calls', label: 'Emergency Calls (Current)', sortable: true, sortKey: 2, thClass: "sorting", showPrev: true, formatter: (v) => toCommas(v)},
        { key: 'CallsPrev', label: 'Emergency Calls (Previous)', sortable: true, sortKey: 3, thClass: "sorting", showPrev: true, formatter: (v) => toCommas(v)},
        { key: 'Calls', label: 'Emergency Calls', sortable: true, sortKey: 2, thClass: "sorting", showCurrent: true, formatter: (v) => toCommas(v)},
        
        { key: 'AvgRollTime', label: 'Avg Roll Time (Current)', sortable: true, sortKey: 4, thClass: "sorting", showPrev: true, formatter: (v) => DateHelper.getTimeFromSeconds(v)},
        { key: 'AvgRollTimePrev', label: 'Avg Roll Time (Previous)', sortable: true, sortKey: 5, thClass: "sorting", showPrev: true, formatter: (v) => DateHelper.getTimeFromSeconds(v)},
        { key: 'AvgRollTime', label: 'Avg Roll Time', sortable: true, sortKey: 4, thClass: "sorting", showCurrent: true, formatter: (v) => DateHelper.getTimeFromSeconds(v)},
        { key: 'arrow', label: 'Service History', sortable: false, thStyle: 'width: 2%', tdClass: "text-center", show: true,},
    ],
    [REPORT_TYPE.TOP_5_SERVICING_DEALERS]: [
        { key: 'DealerName', label: 'Dealer', sortable: false, thClass: "", thStyle: 'width: 20%;', tdClass: 'text-nowrap', show: true},
        { key: 'TotalCalls', label: 'Total Calls', sortable: false, thClass: "", thStyle: 'width: 20%;', show: true, formatter: (v) => toCommas(v)},
        { key: 'EmergencyCalls', label: 'Emergency Calls', sortable: false, thClass: "", thStyle: 'width: 20%;', show: true, formatter: (v) => toCommas(v)},
        { key: 'ScheduledCalls', label: 'Scheduled Calls', sortable: false, thClass: "", thStyle: 'width: 20%;', show: true, formatter: (v) => toCommas(v)},
        { key: 'StockCalls', label: 'Stock Calls', sortable: false, thClass: "", thStyle: 'width: 20%;', show: true, formatter: (v) => toCommas(v)},
    ],
    [REPORT_TYPE.FLEET_HQ_CALLS_BY_STATE]: [
        { key: 'State', label: 'State', sortable: true, sortKey: 0, thClass: "sorting", thStyle: 'width: 20%;', tdClass: 'text-nowrap', show: true},
        { key: 'TotalCalls', label: 'Total Calls', sortKey: 1, sortable: true, thClass: "sorting", thStyle: 'width: 20%;', show: true, formatter: (v) => toCommas(v)},
        { key: 'EmergencyCalls', label: 'Emergency Calls', sortable: true, sortKey: 2, thClass: "sorting", thStyle: 'width: 20%;', show: true, formatter: (v) => toCommas(v)},
        { key: 'ScheduledCalls', label: 'Scheduled Calls', sortable: true, sortKey: 3, thClass: "sorting", thStyle: 'width: 20%;', show: true, formatter: (v) => toCommas(v)},
        { key: 'StockCalls', label: 'Stock Calls', sortable: true, sortKey: 4, thClass: "sorting", thStyle: 'width: 20%;', show: true, formatter: (v) => toCommas(v)},
    ],

    [REPORT_TYPE.EMERGENCY_SERVICE]: [
        { key: 'Date', label: 'Month', sortable: true, sortKey: 0, thClass: "sorting", thStyle: 'width: 20%;', tdClass: 'text-nowrap', show: true, showMonth: true,},
        { key: 'back', label: '', sortable: false, thClass: "text-center", thStyle: 'width: 2%;', tdClass: 'text-nowrap',  show: true, showWeek: true,},
        { key: 'WeekString', label: 'Week', sortable: true, sortKey: 1, thClass: "sorting", thStyle: 'width: 20%;', tdClass: 'text-nowrap',  show: true, showWeek: true,},
        
        { key: 'TotalCost', label: 'Total Service (Current)', sortable: true, sortKey: 2, thClass: "sorting", showPrev: true, formatter: (v) => toMoney(toCommas(v))},
        { key: 'TotalCostPrev', label: 'Total Service (Previous)', sortable: true, sortKey: 3, thClass: "sorting", showPrev: true, formatter: (v) => toMoney(toCommas(v))},
        { key: 'TotalCost', label: 'Total Service', sortable: true, sortKey: 2, thClass: "sorting", showCurrent: true, formatter: (v) => toMoney(toCommas(v))},
        
        { key: 'EmergencyCost', label: 'Emergency (Current)', sortable: true, sortKey: 4, thClass: "sorting", showPrev: true, formatter: (v) => toMoney(toCommas(v))},
        { key: 'EmergencyCostPrev', label: 'Emergency (Previous)', sortable: true, sortKey: 5, thClass: "sorting", showPrev: true, formatter: (v) => toMoney(toCommas(v))},
        { key: 'EmergencyCost', label: 'Emergency', sortable: true, sortKey: 4, thClass: "sorting", showCurrent: true, formatter: (v) => toMoney(toCommas(v))},
        
        { key: 'EmergencyPercent', label: 'Emergency % (Current)', sortable: true, sortKey: 6, thClass: "sorting", showPrev: true, formatter: (v) => toPercentage(toCommas(v))},
        { key: 'EmergencyPercentPrev', label: 'Emergency % (Previous)', sortable: true, sortKey: 7, thClass: "sorting", showPrev: true, formatter: (v) => toPercentage(toCommas(v))},
        { key: 'EmergencyPercent', label: 'Emergency %', sortable: true, sortKey: 6, thClass: "sorting", showCurrent: true, formatter: (v) => toPercentage(toCommas(v))},
    ],
    [REPORT_TYPE.RETREAD_SALES_TO_NEW_TIRES_SALES]: [
        { key: 'Date', label: 'Month', sortable: true, sortKey: 0, thClass: "sorting", tdClass: 'text-nowrap', showMonth: true, show: true},
        { key: 'back', label: '', sortable: false, thClass: "text-center", thStyle: 'width: 2%;', tdClass: 'text-nowrap', show: true, showWeek: true },
        { key: 'WeekString', label: 'Week', sortable: true, sortKey: 1, thClass: "sorting", thStyle: 'width: 20%;', tdClass: 'text-nowrap', showWeek: true, show: true },
        
        { key: 'New', label: 'New Tire', sortable: true, sortKey: 3, thClass: "sorting", showCurrent: true, formatter: (v) => toCommas(v)},
        { key: 'New', label: 'New Tire (Current)', sortable: true, sortKey: 3, thClass: "sorting", showPrev: true, formatter: (v) => toCommas(v)},
        { key: 'NewPrev', label: 'New Tire (Previous)', sortable: true, sortKey: 6, thClass: "sorting", showPrev: true, formatter: (v) => toCommas(v)},
        
        { key: 'Retread', label: 'Retread', sortable: true, sortKey: 2, thClass: "sorting", showCurrent: true, formatter: (v) => toCommas(v)},
        { key: 'Retread', label: 'Retread (Current)', sortable: true, sortKey: 2, thClass: "sorting", showPrev: true, formatter: (v) => toCommas(v)},
        { key: 'RetreadPrev', label: 'Retread (Previous)', sortable: true, sortKey: 5, thClass: "sorting", showPrev: true, formatter: (v) => toCommas(v)},
        
        { key: 'RetreadToNewRatio', label: 'Ratio', sortable: true, sortKey: 4, thClass: "sorting", showCurrent: true, formatter: (v) => toCommas(v)},
        { key: 'RetreadToNewRatio', label: 'Ratio (Current)', sortable: true, sortKey: 4, thClass: "sorting", showPrev: true, formatter: (v) => toCommas(v)},
        { key: 'RetreadToNewRatioPrev', label: 'Ratio (Previous)', sortable: true, sortKey: 7, thClass: "sorting", showPrev: true, formatter: (v) => toCommas(v)},
    ],
    [REPORT_TYPE.SERVICE_EMERGENCY_VS_SCHEDULED]: [
        { key: 'Date', label: 'Month', sortable: true, sortKey: 0, thClass: "sorting", tdClass: 'text-nowrap', showMonth: true, show: true },
        { key: 'back', label: '', sortable: false, thClass: "text-center", thStyle: 'width: 2%;', tdClass: 'text-nowrap', show: true, showWeek: true },
        { key: 'WeekString', label: 'Week', sortable: true, sortKey: 1, thClass: "sorting", thStyle: 'width: 20%;', tdClass: 'text-nowrap', showWeek: true, show: true },
        
        { key: 'TotalCost', label: 'Total Service', sortable: true, sortKey: 2, thClass: "sorting", showCurrent: true, formatter: (v) => toMoney(toCommas(v)) },
        { key: 'TotalCost', label: 'Total Service (Current)', sortable: true, sortKey: 2, thClass: "sorting", showPrev: true, formatter: (v) => toMoney(toCommas(v)) },
        { key: 'TotalCostPrev', label: 'Total Service (Previous)', sortable: true, sortKey: 3, thClass: "sorting", showPrev: true, formatter: (v) => toMoney(toCommas(v)) },
        
        { key: 'EmergencyCost', label: 'Emergency Service', sortable: true, sortKey: 4, thClass: "sorting", showCurrent: true, formatter: (v) => toMoney(toCommas(v)) },
        { key: 'EmergencyCost', label: 'Emergency Service (Current)', sortable: true, sortKey: 4, thClass: "sorting", showPrev: true, formatter: (v) => toMoney(toCommas(v)) },
        { key: 'EmergencyCostPrev', label: 'Emergency Service (Previous)', sortable: true, sortKey: 5, thClass: "sorting", showPrev: true, formatter: (v) => toMoney(toCommas(v)) },
        
        { key: 'EmergencyPercent', label: 'Emergency %', sortable: true, sortKey: 6, thClass: "sorting", showCurrent: true, formatter: (v) => toPercentage(toCommas(v)) },
        { key: 'EmergencyPercent', label: 'Emergency % (Current)', sortable: true, sortKey: 6, thClass: "sorting", showPrev: true, formatter: (v) => toPercentage(toCommas(v)) },
        { key: 'EmergencyPercentPrev', label: 'Emergency % (Previous)', sortable: true, sortKey: 7, thClass: "sorting", showPrev: true, formatter: (v) => toPercentage(toCommas(v)) },
        
        { key: 'ScheduledCost', label: 'Scheduled', sortable: true, sortKey: 8, thClass: "sorting", showCurrent: true, formatter: (v) => toMoney(toCommas(v)) },
        { key: 'ScheduledCost', label: 'Scheduled (Current)', sortable: true, sortKey: 8, thClass: "sorting", showPrev: true, formatter: (v) => toMoney(toCommas(v)) },
        { key: 'ScheduledCostPrev', label: 'Scheduled (Previous)', sortable: true, sortKey: 9, thClass: "sorting", showPrev: true, formatter: (v) => toMoney(toCommas(v)) },
        
        { key: 'ScheduledPercent', label: 'Scheduled %', sortable: true, sortKey: 10, thClass: "sorting", showCurrent: true, formatter: (v) => toPercentage(toCommas(v)) },
        { key: 'ScheduledPercent', label: 'Scheduled % (Current)', sortable: true, sortKey: 10, thClass: "sorting", showPrev: true, formatter: (v) => toPercentage(toCommas(v)) },
        { key: 'ScheduledPercentPrev', label: 'Scheduled % (Previous)', sortable: true, sortKey: 11, thClass: "sorting", showPrev: true, formatter: (v) => toPercentage(toCommas(v)) }
    ],
    [REPORT_TYPE.REPAIR_AND_RETREAD_COST]: [
        { key: 'back', label: '', sortable: false, thClass: "text-center", thStyle: 'width: 2%;', tdClass: 'text-nowrap',  show: true, showWeek: true,},
        { key: 'WeekString', label: 'Week', sortable: true, sortKey: 1, thClass: "sorting", thStyle: 'width: 20%;', tdClass: 'text-nowrap',  show: true, showWeek: true,},
        { key: 'Date', label: 'Month', sortable: true, sortKey: 0, thClass: "sorting", thStyle: 'width: 20%;', tdClass: 'text-nowrap', show: true, showMonth: true},
        
        { key: 'Repair', label: 'Repair', sortable: true, sortKey: 2, thClass: "sorting", showCurrent: true, formatter: (v) => toMoney(toCommas(v))},
        { key: 'Repair', label: 'Repair (Current)', sortable: true, sortKey: 2, thClass: "sorting", showPrev: true, formatter: (v) => toMoney(toCommas(v))},
        { key: 'RepairPrev', label: 'Repair (Previous)', sortable: true, sortKey: 3, thClass: "sorting", showPrev: true, formatter: (v) => toMoney(toCommas(v))},
        
        { key: 'Retread', label: 'Retread', sortable: true, sortKey: 4, thClass: "sorting", showCurrent: true, formatter: (v) => toMoney(toCommas(v))},
        { key: 'Retread', label: 'Retread (Current)', sortable: true, sortKey: 4, thClass: "sorting", showPrev: true, formatter: (v) => toMoney(toCommas(v))},
        { key: 'RetreadPrev', label: 'Retread (Previous)', sortable: true, sortKey: 5, thClass: "sorting", showPrev: true, formatter: (v) => toMoney(toCommas(v))},
        
        { key: 'RepairPercent', label: 'Repair %', sortable: true, sortKey: 6, thClass: "sorting", showCurrent: true, formatter: (v) => toPercentage(toCommas(v))},
        { key: 'RepairPercent', label: 'Repair % (Current)', sortable: true, sortKey: 6, thClass: "sorting", showPrev: true, formatter: (v) => toPercentage(toCommas(v))},
        { key: 'RepairPercentPrev', label: 'Repair % (Previous)', sortable: true, sortKey: 7, thClass: "sorting", showPrev: true, formatter: (v) => toPercentage(toCommas(v))}
    ],
    [REPORT_TYPE.TOP_PRODUCTS]: [
        { key: 'Product', label: 'Product', sortable: false, thStyle: 'width: 40%;',show: true},
        { key: 'TotalSpend', label: 'Total Spend', sortable: false, thStyle: 'width: 25%;',show: true, formatter: (v) => toMoney(toCommas(v))},
        { key: 'Quantity', label: 'Quantity', sortable: false, thStyle: 'width: 25%;',show: true, formatter: (v) => toCommas(v)},
        { key: 'TotalSpendPercentage', label: 'Percentage %', sortable: false, thStyle: 'width: 10%;', show: true, formatter: (v) => toPercentage(toCommas(v))},
    ],
    [REPORT_TYPE.TOP_5_RETREADERS]: [
        { key: 'Nonsig', label: 'Nonsig', sortable: false, thStyle: 'width: 10%;',show: true},
        { key: 'Dealer', label: 'Dealer', sortable: false, thStyle: 'width: 35%;',show: true},
        { key: 'CommonOwner', label: 'Common Owner', sortable: false, thStyle: 'width: 35%;',show: true},
        { key: 'Finished', label: 'Total # Finished Retreads', sortable: false, thStyle: 'width: 10%;',show: true, formatter: (v) => toCommas(v)},
        { key: 'TotalRetreadsPercentage', label: 'Total Retreads %', sortable: false, thStyle: 'width: 10%;',show: true, formatter: (v) => toPercentage(toCommas(v))},
    ],
    [REPORT_TYPE.REJECTED_TIRE_FAILURES_BY_REASON]: [
        { key: 'RejectReason', label: 'Reason', sortable: true, sortKey: 0, thClass: "sorting", thStyle: 'width: 33%;',show: true},
        { key: 'RejectTires', label: 'Quantity',  sortable: true, sortKey: 1, thClass: "sorting", thStyle: 'width: 33%;',show: true,  formatter: (v) => toCommas(v)},
        { key: 'RejectTiresPercentage', label: 'Percentage', sortable: true, sortKey: 2, thClass: "sorting", thStyle: 'width: 33%;',show: true, formatter: (v) => toPercentage(toCommas(v))},    
    ],
    [REPORT_TYPE.RETREAD_TURNAROUND_TIME]: [
        { key: 'Date', label: 'Month', sortable: true, sortKey: 0, thClass: "sorting", thStyle: 'width: 40%;', tdClass: 'text-nowrap', showMonth: true, show: true},
        { key: 'back', label: '', sortable: false, thClass: "text-center", thStyle: 'width: 2%;', tdClass: 'text-nowrap', show: true, showWeek: true },
        { key: 'WeekString', label: 'Week', sortable: true, sortKey: 1, thClass: "sorting", thStyle: 'width: 40%;', tdClass: 'text-nowrap', showWeek: true, show: true },

        { key: 'AverageDays', label: 'Average Days', sortable: true, sortKey: 1, thClass: "sorting",  thStyle: 'width: 60%;', showCurrent: true,},
        { key: 'AverageDays', label: 'Average Days (Current)', sortable: true, sortKey: 1, thClass: "sorting",  thStyle: 'width: 30%;', showPrev: true,},
        { key: 'AverageDaysPrev', label: 'Average Days (Previous)', sortable: true, sortKey: 2, thClass: "sorting",  thStyle: 'width: 30%;', showPrev: true,},      
    ],
    [REPORT_TYPE.REPAIR_SUMMARY]: [
        { key: 'StatusBacking', label: 'Status Backing', sortable: true, sortKey: 0, thClass: "sorting", showCurrent: true},
        { key: 'StatusBackingPeriod', label: 'Status Backing', showPrev: true},
        { key: 'Tires', label: 'Tires', sortable: true, sortKey: 1, thClass: "sorting", show: true, formatter: (v) => toCommas(v)},
        { key: 'NailHoles', label: 'Nail Holes', sortable: true, sortKey: 2, thClass: "sorting", show: true, formatter: (v) => toCommas(v)},
        { key: 'Section', label: 'Section', sortable: true, sortKey: 3, thClass: "sorting", show: true, formatter: (v) => toCommas(v)},
        { key: 'Spot', label: 'Spot', sortable: true, sortKey: 4, thClass: "sorting", show: true, formatter: (v) => toCommas(v)},
        { key: 'Bead', label: 'Bead', sortable: true, sortKey: 5, thClass: "sorting", show: true, formatter: (v) => toCommas(v)},
        { key: 'NailHolesAvg', label: 'Avg Nail Holes', sortable: true, sortKey: 6, thClass: "sorting", show: true, formatter: (v) => toCommas(v)},
        { key: 'SectionAvg', label: 'Avg Section', sortable: true, sortKey: 7, thClass: "sorting", show: true, formatter: (v) => toCommas(v)},
        { key: 'SpotAvg', label: 'Avg Spot', sortable: true, sortKey: 8, thClass: "sorting", show: true, formatter: (v) => toCommas(v)},
        { key: 'BeadAvg', label: 'Avg Bead', sortable: true, sortKey: 9, thClass: "sorting", show: true, formatter: (v) => toCommas(v)},
    ],
    [REPORT_TYPE.TOP_10_CASING_BRANDS_CASING_DURABILITY]: [
        { key: 'Brand', label: 'Brand', sortable: true, sortKey: 0, thClass: "sorting", show: true },
        { key: 'Quantity', label: 'Count', sortable: true, sortKey: 1, thClass: "sorting", show: true, formatter: (v) => toCommas(v) },
        { key: 'TirePercentage', label: 'Percentage', sortable: true, sortKey: 2, thClass: "sorting", show: true, formatter: (v) => toPercentage(toCommas(v))},
    ],
    [REPORT_TYPE.TOP_10_BRANDS_AVERAGE_AGE_CASING_DURABILITY]: [
        { key: 'CasingBrandName', label: 'Brand', sortable: true, sortKey: 0, thClass: "sorting", show: true },
        { key: 'AvgAge', label: 'Avg Age', sortable: true, sortKey: 1, thClass: "sorting", show: true },
    ],
    [REPORT_TYPE.AVERAGE_PREVIOUS_CAPS_CASING_DURABILITY]: [
        { key: 'CasingBrandName', label: 'Brand', sortable: true, sortKey: 0, thClass: "sorting", show: true },
        { key: 'Position', label: 'Position', sortable: true, sortKey: 1, thClass: "sorting", show: true },
        { key: 'AvgPrevCaps', label: 'Avg Previous Caps', sortable: true, sortKey: 2, thClass: "sorting", show: true },
    ],
    [REPORT_TYPE.REJECT_TIRE_RATE_CASING_DURABILITY]: [
        { key: 'Brand', label: 'Brand', sortable: true, thStyle: 'width: 33%;', sortKey: 0, thClass: "sorting", show: true },
        { key: 'Position', label: 'Position', sortable: true, thStyle: 'width: 33%;', sortKey: 1, thClass: "sorting", show: true },
        { key: 'RejectRatePercentage', label: 'Reject Rate %', sortable: true, thStyle: 'width: 33%;', sortKey: 2, thClass: "sorting", show: true, formatter: (v) => toPercentage(toCommas(v)) },
    ],
    [REPORT_TYPE.CASINGS_SUBMITTED_CASING_DURABILITY]: [
        { key: 'CasingBrandName', label: 'Brand', sortable: true, sortKey: 0, thClass: "sorting", show: true },
        { key: 'Position', label: 'Position', sortable: true, sortKey: 1, thClass: "sorting", show: true },
        { key: 'CasingSubmitted', label: 'Casing Submitted', sortable: true, sortKey: 2, thClass: "sorting", show: true, formatter: (v) => toCommas(v) },
    ],
}

export const LocationCodesTable = {
    sortEnum: [
        {value: 0, key: 'select'},
        {value: 1, key: 'code'},
        {value: 2, key: 'name'},
        {value: 3, key: 'address'},
        {value: 4, key: 'city'},
        {value: 5, key: 'state'}
    ],
    fields: [
        { key: 'select', label:'-', sortable: false, thStyle: 'width: 5%'},
        { key: 'code', label: 'Location Code', sortable: true, thClass: "sorting", thStyle: 'width: 20%'},
        { key: 'name', label: 'Name', sortable: true, thClass: "sorting", thStyle: 'width: 20%'},
        { key: 'address', label: 'Address', sortable: true, thClass: "sorting", thStyle: 'width: 25%'},
        { key: 'city', label: 'City', sortable: true, thClass: "sorting", thStyle: 'width: 25%'},
        { key: 'state', label: 'State', sortable: true, thClass: "sorting", thStyle: 'width: 25%'}
    ]
};

export const NonsigTable = {
    sortEnum: [
        {value: 0, key: 'select'},
        {value: 1, key: 'code'},
        {value: 2, key: 'name'},
        {value: 3, key: 'address'},
        {value: 4, key: 'city'},
        {value: 5, key: 'state'}
    ],
    fields: [
        { key: 'select', label:'-', sortable: false, thStyle: 'width: 5%'},
        { key: 'code', label: 'Nonsig', sortable: true, thClass: "sorting", thStyle: 'width: 20%'},
        { key: 'name', label: 'Name', sortable: true, thClass: "sorting", thStyle: 'width: 20%'},
        { key: 'address', label: 'Address', sortable: true, thClass: "sorting", thStyle: 'width: 25%'},
        { key: 'city', label: 'City', sortable: true, thClass: "sorting", thStyle: 'width: 25%'},
        { key: 'state', label: 'State', sortable: true, thClass: "sorting", thStyle: 'width: 25%'}
    ]
};


export const ELEMENT_TO_REMOVE = {
    TABLE: 'REMOVE_TABLE',
    GRAPH: 'REMOVE_GRAPH',
    MAP: 'REMOVE_MAP'
}

export const FD_FILE_TYPES = new Map([
    [ 'PDF', 1 ],
    [ 'EXCEL', 2 ],
    [ 'PNG', 3 ]
])

export const SERVICE_HISTORY_KEYS = new Map([
    [ 'us_national_account', 'na'],
    [ 'canadian_common_owner', 'co'],
    [ 'canadian_nonsig', 'cn'],
    [ 'locations', 'cl'],
    [ 'date_type', 'dr'],
    [ 'service_type', 'st'],
    [ 'unit_location', 'ul'],
    [ 'emergency', 'em'],
    [ 'scheduled', 'sc'],
    [ 'stock', 'st' ],
    [ 'roadside', 'r'],
    [ 'yard', 'y'],
    [ 'shop', 's'],
    [ 'REG', 'reg'],
    [ 'ARE', 'are'],
    [ 'DIS', 'dis'],
])


export const TAB_TYPE_MAP = new Map(Object.keys(TAB_TYPES).map((key) => {
    return [TAB_TYPES[key], TAB_TYPE_LABELS[key].toLowerCase()]
}));

export const REPORT_TYPE_TAB_MAP = new Map(Object.values(REPORT_TYPE).map((value) => {
    switch (value) {
        case REPORT_TYPE.TOP_5_SERVICING_DEALERS:
        case REPORT_TYPE.FLEET_HQ_CALLS_AVG_ROLL_TIME:
        case REPORT_TYPE.FLEET_HQ_CALLS_BY_MONTH:
        case REPORT_TYPE.FLEET_HQ_CALLS_BY_STATE:
            
            return [value, TAB_TYPE_LABELS.SERVICE.toLowerCase()];
        
        case REPORT_TYPE.EMERGENCY_SERVICE:
        case REPORT_TYPE.RETREAD_SALES_TO_NEW_TIRES_SALES:
        case REPORT_TYPE.SERVICE_EMERGENCY_VS_SCHEDULED:
        case REPORT_TYPE.REPAIR_AND_RETREAD_COST:
        case REPORT_TYPE.TOP_PRODUCTS:
            return [value, TAB_TYPE_LABELS.PURCHASES.toLowerCase()];

        case REPORT_TYPE.TOP_5_RETREADERS:
        case REPORT_TYPE.REJECTED_TIRE_FAILURES_BY_REASON:
        case REPORT_TYPE.RETREAD_TURNAROUND_TIME:
        case REPORT_TYPE.REPAIR_SUMMARY: {
            return [value, TAB_TYPE_LABELS.RETREADING.toLowerCase()];
        }
        case REPORT_TYPE.STATISTICS_CASING_DURABILITY:
        case REPORT_TYPE.TOP_10_CASING_BRANDS_CASING_DURABILITY:
        case REPORT_TYPE.TOP_10_BRANDS_AVERAGE_AGE_CASING_DURABILITY:
        case REPORT_TYPE.AVERAGE_PREVIOUS_CAPS_CASING_DURABILITY:
        case REPORT_TYPE.REJECT_TIRE_RATE_CASING_DURABILITY:
        case REPORT_TYPE.CASINGS_SUBMITTED_CASING_DURABILITY: {
            return [value, TAB_TYPE_LABELS.CASING_DURABILITY.toLowerCase()];
        }
        default:           
            break;
    }
}));